$break_point__carro__compra : '991px';

@media(max-width: $break_point__carro__compra) {
    body {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
    }
    .container__carro__compra .breadcrumb__carro__compra .breadcrumb{
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        margin-top: 30px;
    }
    .breadcrumb__carro__compra {
        margin-top: 0 !important;
        margin-left: -15px !important;
    }

}
.item__product__carro__compra {
    background: white;
    margin: 0 !important;
    padding: 23px 32px 23px 28px;
    box-shadow: 0px 0px 30px #0000000A;
    margin-bottom: 30px !important;
    border-radius: 5px;

    @media(max-width: $break_point__carro__compra){
        padding: 12px 16px 12px 16px;
        height: 100%;
        max-height: 100%;
    }
}

.item__product__carro__compra .item__product__information-price {
    
    @media(max-width: $break_point__carro__compra){
        font-weight: 700;
        letter-spacing: 0.7px;
        font-size: 14px;
    }
}

.item__product__carro__compra .item__product__image-cart {
    width: 196px;
    height: 196px;
    margin-right: 0;

    @media(max-width: $break_point__carro__compra){
        width: 76px;
        height: 76px;
    }
}

.item__product__carro__compra .item__product__details {
    margin-top : 0 !important;
}
.item__product__information-price_visibity {
    display : none;

    @media(max-width:$break_point__carro__compra){
        
        margin-top: 5px !important;
        display: flex !important;
        align-items: flex-start !important;


    }

}
.item__product__carro__compra .item__product__information-brand {
    font: normal normal 700 15px/0px Raleway;
    letter-spacing: 1.9px;
    color: #3A3C42;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 5px;
    height: 5px;

    @media(max-width:$break_point__carro__compra){
        
        display: none;
    }

}

.item__product__carro__compra .item__product__information-title
{

    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 20px;
    font-family: Raleway;
    letter-spacing: 0.51px;
    color: #3A3C42;
    opacity: 1;
    /* max-width: 335px; */
    line-height: inherit;
    /* max-height: 60px; */
    height: inherit !important;

    @media only screen and (max-width: 1024px) and (min-width: 992px) {
        font-size: 20px;
        .item__product__information-price {
            margin-right: 5px;
        }
        .item__product__action-remove {
            margin-left: 5px;
        }
    }

    @media(max-width: $break_point__carro__compra) {
        font-size: 13px;
        letter-spacing: 0.65px;
        width: 200px;
    }

    @media(max-width: 500px) {
        font-size: 12px;
        letter-spacing: 0.65px;
        width: 170px;
        padding-top: 15px

    }


}

.item__product__carro__compra .item__product__information-sku
{
    margin-top: 0;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.36px;
    margin-bottom: 0;

    @media(max-width: $break_point__carro__compra) {
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0.19px;
    }
}

.item__product__carro__compra .item__product__information {
    display: flex !important;
    justify-content:center;
    flex-direction: column;
    margin-left: 15px;
    width: 48%;

    @media(max-width: 1200px) {
        max-width: 200px;
    }
}

.item__product__carro__compra .item__product__action
{
    width: 45%;
    display: flex !important;
    align-items: center;
    justify-content:center;
    flex-direction: row !important;
    
    @media(max-width: $break_point__carro__compra) {
        width:100%;
        display: flex !important;
        flex-direction: column-reverse !important;
        align-items: flex-end;
        
    }

    @media(max-width: 400px) {
        width: 10px !important;
    }

}
.item__product__carro__compra .item__product__action-operation {
    position: inherit !important;
    height: 40px !important;
    width: 90px;
    font: normal normal 300 27px/19px Raleway;
    align-items: center;

    @media(max-width: $break_point__carro__compra) {
        height: 22px !important;
        width: 80px !important;
    }
}

.item__product__carro__compra .btn-item-cart {
    height: 51px;
    border: none;
    box-shadow: none;
    color: #494949;
    font-weight: 700;
    display: flex;
    align-items: center;

}

.item__product__carro__compra .item__product__action-operation-number{
    display: flex;
    align-items: center;
}


.order__summary__carro_compra .label__wizard__title__summary {
    height: inherit;
    padding-bottom: 15px;
}

.order__summary__carro_compra .label__wizard__title__summary__underline {
    height: inherit;
}

// media queries to mobile.

@media (max-width: $break_point__carro__compra){

    .has__visibility_in_mobile {
        display: block !important;
        margin-bottom: 30px;
        margin-top: -10px;

        .label__wizard__title__summary {
            font-size: 18px;
            letter-spacing: 0.9px;
            color: #4B4A4B;
            width: 230px;
        }
    }
    .has__visibility_in_mobile_carro__compra {
        margin-top: 10px !important;
    }
    .shadow-down {
        box-shadow: none;
    }
    .logo {
        margin-left: 15px;
    }

    .order__summary__carro_compra .label__wizard__title__summary__underline {
        display:  none;
    }
    .item__product__action-operation-number {
        font-size: 14px;
    }
    .item__product__information-price__carro__compra {
        display: none;
    }

    .order__summary__carro_compra {
        padding: 0 !important;
        border: none;
    }

    .container__carro__compra  {
        margin-top: -15px;
    }
    .row__carro__compra {
        padding-left: 0 !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
 
    .item__product__information-price {
        margin-right: 5px !important;
    }
    .item__product__action-remove {
        margin-left: 5px !important;
    }
}
.container__carro__compra  {
    width: 100% !important;
    max-width: 1640px;
}

@import 'sidebarcart'