
.container__404 , .container__error  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    min-height: calc(100vh - 165px);
    margin: 0 auto;
}
.container__404__group , .container__error__group {
    width: 590px;
    background-color: #ffffff; 
    /* height: 540px; */
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.lbl__title_404 , .lbl__title_description_404 , .lbl__title_error , .lbl__title_description_error {
    font-size: 25px;
    font-family: Merriweather;
    
}
.lbl__title_404 , .lbl__title_error {
    font-weight: 900;
    color:#209E46;
} 

.lbl__title_description_404 , .lbl__title_description_error  {
    font-weight: 500;
    color:#23A049;
}

.lbl__information__404_to__index , .lbl__information__error_to__index {
    text-decoration: underline;
}

.lbl__information__404 , .lbl__information__404_to__index , .lbl__information__error , .lbl__information__error_to__index{
    font-size: 18px;
    font-family: Raleway;
    font-weight: 400;
    width: 320px;
    text-align: center;
}
.img__404 , .img__error {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 256px;
    height: 132px;
}