body {
    font-family: Raleway !important;
    background-color: #F5F5F5;
    overflow: overlay;
}



/*
    not show elemnts to first spring
*/

/* #page-wrap , footer {
    display: none;
} */

footer {
    margin-top: 10px;
    padding-top: 0 !important;
    height: 80px !important;
}
@import './components/new/header';
@import './components/new/cart/cart';
@import './components/new/footer';
@import './components/new/checkout/checkout';
@import './components/new/breadcrumb/breadcrumb';
@import './components/new/checked';
@import './components/new/summary/summary';
@import './components/new/cupon/cupon';
@import './components/new/modal/modal';
@import './components/new/buys/buys';
@import './components/new/404';

.fade__cart_alert {
    transition: opacity 1s linear;
}
.subfooter {
    display : none;
}
// toast notifications










