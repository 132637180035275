

.order__summary__check__out .label__wizard__title__summary {
    height: inherit;
    padding-bottom: 15px;
}

@media (max-width: 991px){
    
    .footer-menu {
        z-index: 99999999999;
    }
    .checkout__terms__conditions {
        line-height:80px !important;
    }
    .order__summary__check__out {
        margin-top: 0 !important;
    }
    .acceptment_contiditions {
        margin-bottom: 20px !important;
    }
    .card-buttons-step {
        position: absolute;
        left: 0;
        bottom: -72vh;
        z-index: 99999;
        right: 0;
        margin-bottom: 5px;
    }
    .btn_span_previous_text {
        font-size: 16px;
    }
    .btn-wizard-next {
        height: 35px !important;
        font-size: 16px;
    }
    .order__summary__check__out {
        padding: 0 !important;
        border: none;
        margin-top: 24px;
    }

    .item__product__checkout {
        height: 127px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
    }

    .search-top {
        z-index: 99999999;
        margin-top: 28px;
    }

    .is__payment_selection {
        margin-top: 40px;
    }
    .lbl__description__option_webpay {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.27px;
        color: #4B4A4B;
    }

    .img_payment {
        width: 75px;
        height: 32px;
    }

    .is__payment_selection__radio {
        height: 55px;
    }

    .is__payment__webpay , .is__payment__webpay__container {
        height: 55px;
    }

    .radio .checked__webpay {
        left: 2%  !important;
        top: 20px !important;
    }
    .frm-group__branch {
        margin-top: 45px !important;
    }

    .acceptment_contiditions {
        font: normal normal 300 12px/16px Raleway;
        letter-spacing: 0.23px;
        margin-top: 32px;

        .checkbox {
            font-size: 12px;
        }

        .check {
            height: 17px;
            width: 17px;
        }
    }
    
    .btn-wizard-finish {
        background: #009A3D 0% 0% no-repeat padding-box;
        border-radius: 2px !important;
        opacity: 0.9 !important;
        height: 35px !important;
        font: normal normal 500 14px/16px Raleway;
        letter-spacing: 0.7px;
        color: #FFFFFF;
    }
    .card-footer {
        display: flex;
        align-items: center;
        justify-content:space-between;
        flex-direction: row-reverse;
        padding: 0;
        margin-right: 0;
    }

    .radio .checked__payment {
        left: 47% !important;
        top: 15px !important;
    }
    .lbl__description__option_payment {
        height: 55px !important;
    }

}

@media (max-width: 768px){
    .search-top {
        z-index: 99999999;
        margin-top: -2px;
    }
}

@media (max-height: 600px) and (max-width: 330px) {
    .modal-cs-2 .modal-container {
        max-height: 180px !important;
        margin-bottom: 5px;
    }
}

@media (min-height: 621px) and (max-width: 768px) {
    .modal-cs-2 .modal-container {
        max-height: 250px !important;
    }
}

@media (min-height: 600px) and (min-width: 1020px) {
    .modal-cs-2 .modal-container {
        max-height: 300px !important;
        margin-bottom: 5px;
    }
}

@media (max-height: 719px) and (min-width: 1020px) {
    .modal-cs-2 .modal-container {
        max-height: 300px !important;
        margin-bottom: 5px;
    }
}

.radio .checked__webpay {
    left: 2%  !important;
}