.container--no__fluid,
.container-desktop {
    width: 1640px;
}

.moving-tab {
    display: none;
}

.card-header {
    background-color: map-get($theme-colors, 'white');
    border: none;
    padding-top: 0;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 0;
    .card-title {
        display: none;
    }
    .description {
        display: none;
    }
}

.card-body {
    padding-top: 27.5px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 0;
}

.card-footer {
    padding-top: 0;
    background-color: white;
    margin-right: 25px;
    border-top: 0;
    font: normal normal bold 20px/13px Raleway;
    letter-spacing: 0.38px;
}

.btn-next,
.btn-wizard-next,
.btn-wizard-finish {
    color: white;
    background: #159a3d 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    height: 50px;
    width: 224px;
    :hover {
        background: #159a3d 0% 0% no-repeat padding-box !important;
    }
    border-radius: 5px;
    border-color: #159a3d;
    font: normal normal bold 20px/13px Raleway;
}
.btn-next:hover,
.btn-wizard-next:hover,
.btn-wizard-finish:hover {
    background: #159a3d 0% 0% no-repeat padding-box !important;
    border-color: #159a3d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background: #159a3d 0% 0% no-repeat padding-box !important;
    border-color: #159a3d;
}

.nav-pills {
    :hover {
        cursor: pointer;
    }

    :first-child {
        max-width: 330px;
        color: #494949;
        font: normal normal 600 20px/19px Raleway;
        letter-spacing: 0.38px;
    }
    :second-child {
        max-width: 330px;
    }
    :third-child {
        max-width: 330px;
    }

    .nav-item {
        padding-top: 61px;
    }
    .nav-link.active {
        color: map-get($theme-colors, 'primary');
        font: normal normal bold 20px/19px Raleway;
        background-color: map-get($theme-colors, 'white');
        border-bottom: 5px solid map-get($theme-colors, 'primary');
        opacity: 1;
        letter-spacing: 0.38px;
        padding-bottom: 23.5px;
        checked {
            color: map-get($theme-colors, 'primary');
        }
    }
}

.breadcrumb__wizard {
    margin-top: 50px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    margin-left: -15px;
    .breadcrumb {
        background: #f5f5f5 0% 0% no-repeat padding-box;
    }
}

.btn-previous {
    margin-left: 10px !important;
    background-color: transparent !important;
    border: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.btn_span_previous_text{
    margin-left: 10px !important;
    font-style:normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.38px;
    font-size: Raleway;
    color: #4B4A4B;
    margin-left: 12px !important;
}

.btn-previous:hover, .btn-previous:focus , .btn-previous:active ,.btn-previous:not(:disabled):not(.disabled):active {

    background-color: transparent !important;
    border: none !important;
}

@import 'delivery/delivery';
@import 'client/client';
@import 'payment/payment';
