ul.navbar-nav {
    li {
        //font: normal normal medium 16px/19px Raleway;
        font-family: 'Raleway' !important;
        font-size: 16px !important;
        line-height: 19px !important;
        letter-spacing: 0.8px !important;
        opacity: 1 !important;
        font-weight: 300 !important;
        font-style: normal !important;
        font-variant: normal !important;
        margin-right: 19px !important;
        a {
            color: $nav-link !important;
        }
    }
}

.navbar-fixed-cs {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 1030;
    height: 100px;
    box-shadow: 0px 10px 30px #0000000a;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0;
}

.navbar-brand {
    margin-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
}

nav img.icon-header {
    width: 21px;
    margin-right: 18px;
    cursor: pointer;

    @media (min-width: 768px) {
        width: 30px;
    }
}

.input-group__color {
    height: 45px !important;
    width: 354px !important;
    border: none!important;
    margin-right:  18px;
    display: none;
}

.input-search__header {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0 !important;
    height: 45px;
    color: #495057;
    border: 0.30000001192092896px solid #159a3d !important;
    border-top-left-radius: 23px !important;
    border-bottom-left-radius: 23px !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.input-group__button__append {
    margin-left: 0px;
}
.btn__group__search__button {
    background-color: #159a3d;
    border-radius: 23px;
    height: 45px !important;
}

.btn__search__button {
    background: #159a3d 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #159a3d;
    border-radius: 0px 100px 100px 0px;
    opacity: 1;
    width: 45px;
    height: 45px;
    display: inline-block;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0 23px 23px 0px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.shadow-down {
    -webkit-box-shadow: 0px 10px 30px #0000000a;
    -moz-box-shadow: 0px 10px 30px #0000000a;
    box-shadow: 0px 10px 30px #0000000a;
}
.shadow-up {
    -webkit-box-shadow: 0px 10px 30px #0000000a;
    -moz-box-shadow: 0px 10px 30px #0000000a;
    box-shadow: 0px 10px 30px #0000000a;
}

.d-search-mobile {
    display: block;
}

@media (min-width: 1279px) {
    .d-search-mobile {
        display: none !important;
    }
}

@media (min-width: 1280px) {
    .input-group__color {
        display: flex;
    }

    .container-desktop {
        max-width: 100%;
    }

    .container {
        justify-content: flex-start;
    }
}

@media (min-width: 769px) {
    .container-sm,
    .container-md,
    .container {
        max-width: 100%;
    }
    //
    .search-top {
        margin-top: 30px;
    }
}

.dropbtn {
    background-color: transparent;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
    height: 90px;
    margin-top: 65px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -15px;
    top: 60px;
}

.dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
    font-size: Raleway;
    cursor: default;
}

.dropdown-content a:hover {
    background-color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
}

.dropdown:hover .dropbtn {
    background-color: transparent;
}

.container-search-instants_desktop
{

    position: absolute;
    background-color: white;
    top: 80px;
    width: 300px;
    margin-left: 15px;
    overflow-y: auto;
    max-height: 450px;
}
.item__title__search__item {
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    letter-spacing: 0.3px;
    color: #4B4A4B;
    text-transform: capitalize;
}

.item__container__figure {
    width: 20%;
    img {
        height: 50px;
        margin: 10px;
    }
}
.item__container__description {
    width: 75%;
    max-height: 70px;
    line-height: 70px;
    margin-left: 5%;
}
.no__records {
    font-size: 14px;
    font-family: Raleway;
    text-align: center;
    padding-top: 15px;
}
@media (max-width: 768px) {
    .navbar-fixed-cs {
        height: 68px;
    }
    .dropdown {
        height: inherit;
        margin-top: inherit;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        left: -30px;
        top: 45px;
    }
}
