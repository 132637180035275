// SIDEBAR CART

.modal-backdrop
{
    background-color: transparent;
}

.sidebar-car-cs .modal-body {
    margin-top: 20px !important;
    margin-left: 26px !important;
    margin-right: 26px !important;
}

.modal-cs-2 .modal-body {
    /* padding: 0 1rem 1rem 1rem; */
    padding-left: 0;
    padding-right: 0;
}

.sidebar-car-cs .modal-dialog .modal-content {
    border: 1px solid #ffffff;
}

.d-container-title-cart
{
    margin-top: 0px;
}
.lbl__tittle__cart {
    text-align: center;
    font-family: 'Raleway';
    font-style:  normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.8px;
    color: #159A3D;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 0px;
}

.btn__remove__all__cart {

    width: 107px;
    height: 33px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #159A3D;
    border-radius: 23px;
    opacity: 1;

    text-align: center;
    /* font: normal normal 300 13px/15px Raleway; */
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    font-family: Raleway;


    letter-spacing: 0.65px;
    color: #3A3C42;
    opacity: 1;

}

.item__product__image-cart
{
    width: 89px;
    height: 88px;
    opacity: 1;
    margin-right: 28px;
}

.item__product__details{
    margin-top: .8rem;
    width: 447.25px;
}

.item__product__information-title {
    text-align: left;
    /* font: normal normal bold 25px/30px Raleway; */
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    font-family: Raleway;
    letter-spacing: 0.7px;
    color: #3A3C42;
    opacity: 1;
    height: 32px;
}

.item__product__information-price {
    height: 19px;
    text-align: center;
    /* font: normal normal 600 16px/19px Raleway; */
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: Raleway;
    letter-spacing: 0.8px;
    color: #159A3D;
    opacity: 1;
}



.shop__cart__empty-title {
    height: 30px;
    text-align: center;
    /* font: normal normal bold 25px/30px Raleway; */
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    font-family: Raleway;

    letter-spacing: 0px;
    color: #159A3D;
    opacity: 1;
    margin-bottom: 35px;
    margin-top: 35px;
}

.shop__cart__empty-images {
    text-align: center;
    height: 148px;
    margin-bottom: 28px;
}

.shop__cart__empty-images__empty
{
    width: 147px;
    height: 148px;
}

.sidebar-car-empty .modal-body {
    margin: 0 !important;
    padding-top: 10px;
    padding-right: 10.25px;
}

.shop__cart__empty-information {
    height: 21px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    font-family: Raleway;
    letter-spacing: 0.34px;
    color: #494949;

    a {
        text-align: center;
        text-decoration: underline;
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        font-family: Raleway;
        letter-spacing: 0.34px;
        color: #159A3D;
        margin-left: 5px;
    }
}


// operations

.item__product__action-operation {
    border: 1px solid #E2E2E2;
    opacity: 1;
    width: 67px;
    height: 25px;
    position: relative;
    top: -15px;
}


.item__product__action-operation-plus , .item__product__action-operation-minus{
/*     padding-top: 13.65px;
    padding-bottom: 9.88px;
    padding-left: 9.86px; */
    /* width: 7px; */
    height: 1px;
    
}




.cart__detail__summary-total {
    margin-bottom: 30px;

    .cart__detail__summary-total-price  {

        height: 21px;
        text-align: center;
        font: normal normal bold 18px/21px Raleway;
        letter-spacing: 0.9px;
        color: #3A3C42;
        opacity: 1;
        margin-bottom: 0;
    
    }

    .cart__detail__summary-total-title {
    
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: Raleway;
        letter-spacing: 0.8px;
        color: #159A3D;
        opacity: 1;
        margin-bottom: 0;
    
    }

}

.cart__detail__summary-discount {
    
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-family: Raleway;
        
    letter-spacing: 0.8px;
    color: #159A3D;
    opacity: 1;
    margin-bottom: 12px;

    .cart__detail__summary-discount-price  {

        height: 21px;
        text-align: center;
        font: normal normal bold 18px/21px Raleway;
        letter-spacing: 0.9px;
        color: #3A3C42;
        opacity: 1;
        margin-bottom: 0;
    
    }

    .cart__detail__summary-discount-title {
    
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: Raleway;
        letter-spacing: 0.8px;
        color: #159A3D;
        opacity: 1;
        margin-bottom: 0;
    
    }

}

.btn__continous__buying {
    height: 41px;
    background: #006829 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    margin-bottom: 20px;
    padding: 0;
    display: block;

    div {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: Raleway;
        /*font: normal normal medium 16px/19px Raleway;*/
        letter-spacing: 0.8px;
        color: #FFFFFF !important;
        opacity: 1;    
        margin-top: 12px;
        margin-bottom: 9.62px;

    }
    
}

.btn__next__page {

    height: 41px;
    background: #009A3D 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    padding: 0;
    display: block;

    div {
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: Raleway;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 12px;
        margin-bottom: 9.62px;
    }

}

.shop__cart__header__mobile , .shop_cart__header__mobile-logo {
    display: none;
}

.item__product {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 769px) {
    .container-sm, .container-md , .container {
        max-width: 100%;
    }
    //
    .search-top {
        margin-top: 30px;
    }

    .sidebar-car-cs .modal-dialog {
        margin-top: 100px !important;
        margin-right: 80px !important;
    }

    .sidebar-car-empty .modal-dialog {
        position: fixed;
        margin: 0rem;
        width: 100%;
        height: 400px !important;
        width: 435px !important;
        right: 0px;
    }

}

@media (max-width: 768px) {

    .sidebar-car-cs .modal-dialog .modal-content {
        border: 1px solid #ffffff;
        max-width: 475px !important;
    }

    .sidebar-car-empty .modal-dialog {
        width: 276px;
        height: 277px !important;
        margin: 0 auto;
        box-shadow: 0px 15px 30px #0000001A;
        border: 0.30000001192092896px solid #C6C6C6;
        border-radius: 0px 0px 5px 5px;
        opacity: 1;
        position : initial !important;
        margin-top: calc(50vh - 135px);

    }

    .shop__cart__empty-title {
        text-align: center;
        font-family: Raleway;
        font-style:  normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #159A3D;
        opacity: 1;
        margin-bottom: 15px;
        margin-top: 31px;

    }

    .shop__cart__empty-images {
        margin-bottom: 10px;
        height: 92px;
    }

    .shop__cart__empty-images__empty {
        height: 92px;
        width: 90px;
    }

    .shop__cart__empty-information {
        height: 14px;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        font-family: Raleway;
        letter-spacing: 0.23px;
        color: #494949;
        /* width: 276px; */
        margin: 0 auto;
    
        a {
            text-align: center;
            text-decoration: underline;
            font-style: normal;
            font-variant: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            font-family: Raleway;
            letter-spacing: 0.23px;
            color: #159A3D;
            margin-left: 5px;
        }
    }

    // cart with products

    .lbl__tittle__cart {
        text-align: center;
        font-family: 'Raleway';
        font-style:  normal;
        font-variant: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.7px;
        color: #159A3D;
        text-transform: uppercase;
        opacity: 1;
    }

    .btn__remove__all__cart {

        width: 93px;
        height: 28px;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border: 0.30000001192092896px solid #159A3D;
        border-radius: 23px;
        opacity: 1;
    
        text-align: center;
        /* font: normal normal 300 13px/15px Raleway; */
        font-style: normal;
        font-variant: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        font-family: Raleway;
        letter-spacing: 0.6px;
        color: #3A3C42;
        opacity: 1;
    
    }

    .shop__cart__header__mobile {
        display: block;
        height: 100px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 30px #0000000A;
        opacity: 1;
        width: 100%;

    }

    .shop_cart__header__mobile-logo {
        display: block;
        img {
            width: 130px;
            height: 37px;
            opacity: 1;
            margin-top: -30px;
            margin-bottom: 33px;
        }
    }

    .sidebar-car-cs .modal-body {
        margin-top: 0px !important;
        margin-left: 22px !important;
        margin-right: 22px !important;

    }

    .shop_cart__header__mobile-close {
        padding-left: 22px;
        padding-top: 33px;
    }

    .exit-sidebar {
        display: none !important;
    }

    .d-container-title-cart {
        margin-top: 27px;
    }

    .item__product__image-cart {
        width: 76px;
        height: 76px;
        opacity: 1;
        margin-right: 19px;
    }

    .item__product__information-title {
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        font-family: Raleway;
        letter-spacing: 0.27px;
        color: #3A3C42;
        opacity: 1;
        height: 32px;
        padding-top: 7px;
        margin-bottom: 0;
    }

    .item__product {
        margin-top: 6px;
        margin-bottom: 6px;
        max-height: 76px;
    }

    .item__product__details
    {
        height: 76px;
        margin-top: 0;
    }
    .item__product__action-remove
    {
        padding-top: 0px;
    }

    .item__product__information-price {
        height: 37px;
        vertical-align: middle;
        line-height: 37px;
    }

    .item__product__action-operation {
        height: 22px;
        position: relative;
        top: -15px;
    }


}

.item__product__action-operation_sidebar-cart {
    top: 0px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 447.25px;
    }
}

@import 'carrocompra';

.sidebar-car-empty .modal-dialog .modal-content{
    height: 400px !important;
}

@media(max-width:768px){
    .sidebar-car-empty .modal-dialog .modal-content{
        height: 250px !important;
    }   
    .sidebar-car-empty .modal-body {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}