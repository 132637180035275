@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './components/variables';

@import '~bootstrap/scss/bootstrap';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '~animate.css/animate.css';

@import './components/header';
@import './components/footer';
@import './components/cover-page';
@import './components/two-cols';
@import './components/newsletter';
@import './components/forms';
@import './components/cards';
@import './components/pagination';
@import './components/modals';
@import './components/fonts';
@import './components/carrousel';
@import './components/sidecard';
@import './components/cart';
@import './components/tab';

// Custom (Revisar) - todo lo generico
@import './components/custom';


// Custom (Revisar - v2) - todo lo generico
@import './components/custom_new';