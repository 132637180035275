.tabs{
	&-login{
		font-family:$font-1 !important;
		& .nav-link{
			font-size: 13px;
			padding: 15px 5px;
		}

		& .btn{
			font-size: 20px
		}
	}
}