.IconItem-Wizard {
    display: none;
}
@media (min-width: 992px) and (max-width: 1024px) {
    .lbl__description__option_payment {
        font-size: 11px !important;
    }
}
@media (max-width: 991px) {
    .li__line__step {
        z-index: 0;
    }
    .li__line__step:before {
        background: #159a3d;
        content: '';
        width: 40%;
        height: 5px;
        top: 45px;
        position: absolute;
        /* z-index: 2; */
       margin-left: 30px;
    }

    .li__line__step_end::before {
        background: transparent !important;
    }
    .li__line__step::last-child {
        z-index: -1;
    }

    .container--no__fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .card-wizard {
        border: none;
        margin-left: 0 !important;
    }
    .card-header , .card-body {
        padding: 0;
    }

    .IconItem-Wizard {
        height: 60px !important;
        width: 60px !important;
    }
    .IconItem-Wizard {
        display: block;
        margin-top: 15px;
    }
    .clsWizardItemResponsive {
        border: none !important;
        margin:0 !important;
        padding: 0 !important;
    }
    .ItemStepNameResponsive {
        display: none;
    }
    
    .nav-pills {
       display: flex !important;
       justify-content: space-between !important;
       /* border: 1px solid green; */
    }
    .nav-pills  .nav-item {
        width: inherit !important;
        padding-top: 0;
    }
    .logo_wizard {
        margin-left: 0px;
    }
    .container_wizard {
        width: 100%;
    }

    .container--no__fluid_wizard {
        max-width: 100% !important;
        width: 100% !important;
        margin-left: 0 !important;

    }

    .cart-delivery-type
    {
        margin-right: 15px;
    }

    .sidebar-car-cs , nav {
        z-index: 9999999999;
    }

    .lbl__description__option_payment  {
        font-size: 12px !important;
        letter-spacing: 0.23px !important;
        position: absolute;
    }

    .form-chekout .lbl__radio__container {
        align-items: center !important;
    }
    .form-chekout .rs-radio-inline ,  .cart-delivery-type-box {
        height: 130px;
    }

    .img_payment__dispatch {
        position: absolute;
        top: 50px;
        margin-right:0;

    }

    .img_payment__retirement {
        position: absolute;
        top: 45px;
        margin-bottom: 1px;
        margin-right:0;

    }

    .form-chekout .lbl__description__option_payment {
       /* position: absolute !important;
       margin-left: 45% !important;
       top: 86px;
       width: 70px;
       height: 50px;
       line-height: 15px; */
       text-align: center;
       margin-left: 8px !important;
    }

    .radio .checked__delivery {
        top: 90px !important;
        left: 35% !important;
        margin-left: 35%;
    }

    .lbl__description__option__type_document
    {
        font-size: 18px;
        letter-spacing: 0.9px;
        margin-left: 40%;
    }

    .content__delivery__type {

        height: 55px;
    }

    .radio .check__delivery__type {
        top: 20px !important;
    }

    nav {
        z-index: 999999999;
    }

    .item__product__checkout {
        max-height: 120px !important;
    }
    .item__product__details__checkout {
        height: 120px !important;
    }
    .container__modal__width {
        z-index: 999999999;


    }

    .input__client_data_phone {
        background: #F7F7F7 0% 0% no-repeat padding-box !important;
    }
    .client__data__rut , .input-group-phone{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .input-group-phone {
        margin-top: 15px;
    }

    .is__data__bill {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .input__client_data_dispatch__address {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .lbl__phone {
        font-weight: 700;
    }
    .label__wizard__ticket_bill_data {
        width: 125px !important;
    }

}