/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 0px;
    height: 0px;
    left: 0px;
    top: 0px;
}

.content-details-modal-cart {
    display: flex;
    flex-wrap: wrap;
    .content-price-cdmc {
        flex: 1 0 100%;
        .price-title-cdmc {
            flex: 0 0 auto;
        }
        .price-cdmc {
            flex: 1 1 auto;
            font-family: 'Nunito', sans-serif;
        }
    }
    .content-quantity-cdmc {
        display: flex;
        flex: 1 0 100%;
        .content-actions-quantity-cdmc {
            display: flex;
            align-content: center;
            small {
                margin: 0px 10px;
                position: relative;
                top: 5px;
            }
            .btn-item-cart {
                padding: 0px 10px;
                cursor: pointer;
                width: 25px;
                height: 25px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-content: center;
                box-shadow: 0 0 2px #ccc;
            }
        }
    }
}

.modal-cs-2 {
    .modal-container {
        max-height: calc(100vh - 260px) !important;
    }
    .modal-header {
        z-index: 10;
    }
}
