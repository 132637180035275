.order_summary {
    padding: 44px;
    .modal-container {
        max-height: 475px;
        overflow-y: auto;
        margin-top: 5px;
    }
}
.container__summary__checkout {
    max-height: 850px;
}

.label__wizard__title__summary {
    font: normal normal 700 25px/30px Raleway;
    height: 45px;
    border-bottom: 5px solid #159A3D;
    opacity: 1;
    letter-spacing: 1.25px;
    margin:0;

}

.label__wizard__title__summary__underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 45px;
}

.cart_detail__summary-checkout 
    , .cart__detail__summary-subtotal-title , .cart__detail__summary-discount-cupon-title , .cart__detail__summary-total-title{
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-family: Raleway;
    letter-spacing: 0.3px;
    color: #4B4A4B;
    margin-bottom: 16px;
}

.cart_detail__summary-checkout , .cart__detail__summary-subtotal-price
{
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    font-family: Raleway;
    letter-spacing: 0.3px;
    color: #494949;
    opacity: 1;

    .cart__detail__summary-total-price {
        color: #159A3D;

    }
}
.cart__detail__summary-subtotal-title , .cart__detail__summary-subtotal-price {
    margin-top: 32px;
}
.cart__detail__summary-total {
    margin-top: 2rem;
    margin-bottom: 0;
}

.cart_detail__summary-checkout {
    margin-bottom: 0;
}

.btn__checkout {
    line-height: 42px;
    height: 42px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 16px;
    font-family: Raleway;
    letter-spacing: 0.8px;
    color: white;
}

.btn__checkout:hover {
    color: white !important;
}

.btn__checkout__summary {
    margin-top: 5px;
}

.cart__detail__summary-total .cart__detail__summary-total-price {
    margin-bottom: 0.5rem;
}
.checkout__terms__conditions {
    line-height:80px;
    text-align:center;
    margin: 0;
    letter-spacing: 0.3px;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    color: #3A3C42;

}