.cart-remove-item-md {
    font-size: 1.5rem;
    color: red;
    font-family: Arial;
    cursor: pointer;
    position: absolute;
    font-weight: bold;
    display: flex;
    right: 10px;
    top: -20px;
}

.btn-item-cart {
    padding: 0px 10px;
    cursor: pointer;
    box-shadow: 0 0 2px #ccc;
}

.btn-item-count {
    margin: 0 10px;
}

.cart-remove-item-md2 {
    font-size: 1.5rem;
    color: red;
    font-family: Arial;
    cursor: pointer;
    position: absolute;
    font-weight: bold;
    right: -15px;
    top: -50px;
}

// Sidebar overlay
.sidebar-car-cs {

    .modal-header {
        height: 30px;

        .exit-sidebar {
            font-size: 2rem;
            color: red;
            font-family: Arial;
            cursor: pointer;
            font-weight: bold;
            right: 20px;
            position: absolute;
        }
    }

    .modal-body {
        margin: 1rem !important;
    }

    .row-cart {

        .row-cs {
            padding: 20px 0px;
            border-radius: 10px;
            box-shadow: 0px 0px 3px 1px #e6e5e5;
            margin: 30px 0px;

            h6 {
                margin-top: 20px;
            }
        }
    }

    
    @media (min-width: 768px) {

        .modal-dialog-scrollable .modal-body {
            overflow-y: hidden !important;
        }

        .modal-dialog {
            position: fixed;
            margin: 0rem;
            width: 100%;
            height: 100%;
            right: 0px;

            .modal-content {
                /* height: 100% !important; */
                height: 585px !important;

                .modal-body {
                    .modal-container {
                        padding: 5px;
                        /*max-height: calc(100vh - 260px) !important;*/
                        /* max-height: 210px !important; */
                        overflow-y: auto;
                    }
                }
            }
        }    
    }

    @media (max-width: 768px) {
        .modal-dialog {
            position: fixed;
            margin: 0rem;
            width: 100%;
            height: 100%;
            right: 0px;

            .modal-content {
                // height: 100% !important;
                height: 620px !important;
                
                .modal-body {
                    .modal-container {
                        padding: 5px;
                        overflow-y: auto;
                    }
                }
            }
        }    
    }
 
}
