
.wizard-container , .form-user-register-modal{
    textarea:focus,
    input:focus,
    .uneditable-input:focus {
        border-color: #159a3d !important;
        box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px #159a3d !important;
        outline: 0 none !important;
    }
}

input {
    padding: 15px !important;
}

.backdrop_default
{
    background-color: #000000;
}

.input-search__header:focus {
    box-shadow: none !important;
    /* margin-left: 15px !important; */
}

/* custom checkbox */
.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* create custom checkbox */
.check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #159a3d;
    margin-top: -3px;
}

/* on mouse-over, add border color */
.checkbox:hover input ~ .check {
    border: 1px solid #159a3d;
}

/* add background color when the checkbox is checked */
.checkbox input:checked ~ .check {
    background-color: #159a3d;
    border: none;
}

/* create the checkmark and hide when not checked */
.check:after {
    content: '';
    position: absolute;
    display: none;
}

/* show the checkmark when checked */
.checkbox input:checked ~ .check:after {
    display: block;
}

/* checkmark style */
.checkbox .check:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// radio buttton

/* custom radio */
.radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* hide the browser's default radio button */
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* create custom radio */
.radio .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #159a3d;
    border-radius: 50%;
}

/* on mouse-over, add border color */
.radio:hover input ~ .check {
    border: 2px solid#159A3D;
}

/* add background color when the radio is checked */
.radio input:checked ~ .check {
    background-color: #159a3d;
    border: none;
    border: 1px solid white !important;
}

/* create the radio and hide when not checked */
.radio .check:after {
    content: '';
    position: absolute;
    display: none;
}

/* show the radio when checked */
.radio input:checked ~ .check:after {
    display: block;
}

/* radio style */
.radio .check:after {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

label input:checked + .cart-delivery-type-box {
    background-color: #159a3d;

    .lbl__description__option__type_document {
        color: white;
    }
}



/* custom radio */
.radio2 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-family: Raleway;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* hide the browser's default radio button */
.radio2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* create custom radio */
.radio2 .check2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #159a3d;
    border-radius: 50%;
}

/* on mouse-over, add border color */
.radio2:hover input ~ .check2 {
    border: 2px solid#159A3D;
}

/* add background color when the radio is checked */
.radio2 input:checked ~ .check2 {
    background-color: #159a3d;
    border: none;
    border: 1px solid white !important;
}

/* create the radio and hide when not checked */
.radio2 .check2:after {
    content: '';
    position: absolute;
    display: none;
}

/* show the radio when checked */
.radio2 input:checked ~ .check2:after {
    display: block;
}

/* radio style */
.radio2 .check2:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.check-term-conditions {
    height: 18px;
    width: 18px;
    margin-top: 1px;
}

.checkbox .check-term-conditions:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

@media(max-width:991px){
    .radio .check__document_ticket{
        left: 20% !important;
    }
    .radio .check__document_bill{
        left: 20% !important;
    }
}
