$theme-colors: (
  "primary": #009A3D,
  "info": #3b5998,
  "danger": #ff4136,
  "secondary" : #4B4A4B,
  "tertiary" : #F5F5F5,
  "white":  #ffffff
);

$colors: (
    "green-thermomix" : #009A3D,
    "title-black" : #4B4A4B,
    "white": #ffffff,
    "wizard-title" :  #494949
);

$color-black: black;
$font-1:'Nunito', sans-serif;

$body-bg: #F5F5F5;
$body-color: #263236;
//$nav-link : #4B4A4B;

$columns: 12;


$nav-link : #3A3C42;



