.breadcrumb-separator {
    margin-left: 8px;
    margin-right: 8px;
}
@media (max-width: 991px) {
    .breadcrumb__wizard {
        margin-top: 35px;
    }
    .breadcrumb__wizard .breadcrumb {
        background: #fff;
        margin-bottom: 0;
        padding-top: 0;
    }
}