body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Merriweather', serif;
}

#outer-container {
    margin-top: 70px;
}

h2 {
    font-size: 1.9rem;
    font-weight: 700;

    &.main-title {
        padding: 3rem 1rem;
        color: map-get($colors, 'title-black');

        @media (max-width: 768px) {
            //font-size: 1.5rem !important;
            padding: 1.5rem 0rem 1rem 0rem;
        }
    }
}

// SUBTITULOS
h4 {
    .icon-subtitle {
        padding: 5px 5px;
        margin-top: -5px;
        max-width: 30px;
    }

    &.sub-main-title {
        margin: -40px 0 20px 0;
        font-size: 1.3rem;
    }

    &.sub-main-title-2 {
        margin: -50px 0 40px 0;
        font-size: 1.3rem;
    }

    &.icon-back {
        color: map-get($theme-colors, 'primary');
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.btn {
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.btn-lg {
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 15px !important;
        align-items: center;
        text-align: center !important;
    }
}

// .container-margin-cs {
//     margin-top: 4rem;
//     margin-bottom: 2.5rem;
// }

.title-work-us {
    display: block;
}

.search-receta {
    margin: 4rem 0px -1px 0px;
    padding-top: 20px;
    border: 0px;
    background: #fff;

    input {
        border: 0px;
        font-weight: bold;
        font-size: 100% !important;

        &::placeholder {
            font-weight: bold;
        }
    }

    span.input-group-text {
        background: #fff;
        border: 0px;
    }
}

a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.tienda-presentacion {
    .card-cs-5 {
        a {
            font-size: 2vmax;
            white-space: nowrap;
        }
    }
    .img-container-cs {
        text-align: center;
    }

    .img-cs {
        max-width: 400px;
        top: -8rem;
        position: relative;
        background: transparent !important;
        margin-bottom: -5rem;
    }

    @media (max-width: 768px) {
        .img-cs {
            max-width: 150px;
            top: 5rem;
            position: relative;
            background: transparent !important;
            margin-bottom: 5rem;
        }
    }
}

.img-container-cs {
    .img-cs {
        width: 100%;
    }
}

.producto-precio {
    color: map-get($colors, 'green-thermomix'); 
    padding-top: 10px;
    padding-bottom: 10px;
}

.green-thermomix {
    color: map-get($colors, 'green-thermomix') !important;
}

.container-search-instants {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    //top: -20px;
    box-shadow: 0px 10px 10px 0.1px #444444a1;

    .row-search-item {
        margin: 10px;
        border-bottom: 10px solid map-get($theme-colors, 'tertiary') !important;
    }

    h4 {
        font-size : 1rem;
    }
}

.navbar-fixed-cs {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 1030;
}

h4.receta-title-seccion {
    color: map-get($theme-colors, 'primary');
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0.5rem 0rem;
}

.input-group-text {
    background: transparent;
    border: 0px;
}

.container-history2 {
    cursor: pointer;
    padding: 0px 0px 20px 0px;
}

.container-cart-list {
    padding: 1rem 0rem;

    .cart-list-title-producto {
        padding: 1rem;

        h3 {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}

.title-main-top {
    margin-top: -5px;
}

.receta-filtro {
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;

    .btn-limpiar-filtro {
        padding: 10px 0;
        color: map-get($colors, 'green-thermomix');
    }

    h5 {
        font-size: 1rem !important;
        width: 100%;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #CCC;
        text-align: justify !important;

        .icon-title-filtro {
            font-size: bold;
            margin-right: 10px;
            background: #e6e5e5;
            padding: 0 5px;
        }
    }

    label {
        font-size: 0.7rem;
    }
}


.badge-item-count {
    margin-left: -15px !important;
    margin-top: -5px !important;
    position: absolute;
    padding: 0;
    width: 16px;
    height: 16px;
    font-weight: 700;
    font-size: 12px;
    font-family: Arial;
    text-align: center;
    vertical-align: middle;
    line-height: 16px;
    border-radius: 50%;
}

.borderless td, .borderless th {
    border: none;
}

.search-top {
    position : fixed;
    width: 100%;
    z-index: 1029;
    padding: 0;
    border-bottom: 1px solid #eaeaea;
}

p {
    .stars {
        a {
            position: relative;
            height: 1em;
            width: 1em;
            text-indent: -999em;
            display: inline-block;
            text-decoration: none;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 1em;
                height: 1em;
                line-height: 1;
                content: "\e021";
                text-indent: 0;
            }
        }
    }
}

.bg-green-thermomix {
    background : map-get($colors ,'green-thermomix');
    color : #fff;

    a {
        color: #fff !important;
    }
}

h5.title-home-demo {
    font-size: 15px;
    padding: 5px 0;
    font-weight: bold;
    margin: 0px;
}

.row-table {
    @media (max-width: 768px) {
        display: table;  

        .row-head {
            display: table-header-group;
        }
    }
}


@media (max-width: 768px) {

    h1, h2, h3, h4, h5, h6 {
        font-size: 130% !important;
    }
}

h4.btn-filtro {
    a {
        color : map-get($theme-colors, 'secondary') !important; 
    }
}

.btn-finalizar-compra {

    @media (min-width: 768px) {
        top: 70px;   
    }
}

.receta-detalle {
    
}