.lbl__description__option_payment , .lbl__description__option_payment_conditions {
    letter-spacing: 0.38px;
    color: #494949;
    font: normal normal bold 20px/65px Raleway;
    height: 65px;
    line-height: 65px;
    margin: 0;
    position: absolute;
    left: 10%;
}

label input:checked + .cart-delivery-type-box .lbl__description__option_payment_conditions {
    color: white;
}
label input:checked + .cart-delivery-type-box .lbl__description__option_payment {
    color: white;
}

 

.lbl__radio__container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.img_payment {
    width: 150px;
    height: 65px;
    line-height: 65px;
    margin-right: 40px;
}

@media(min-width:992px){
    .radio .checked__payment {
        left: 5% !important;
    }
}

.is__payment .rs-radio-checker {
    height: 130px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 0.5px solid #c3c3c3;
    opacity: 1;
    width: 100%;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lbl__radio__container-input {
        height: 65px;
        line-height: 65px;
    }
}

.is__payment_selection .label__wizard__title {
    font: normal normal bold 18px/19px Raleway;
    letter-spacing: 0.34px;
    color: #4b4a4b;
    opacity: 1;
    width: 251px;
    margin-bottom: 30px;
}

.is__payment_selection__radio {
    padding-left: 15px !important;
}

.acceptment_contiditions {
    margin-top: 69px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 51px;
    font: normal normal 500 14px/19px Raleway;
    letter-spacing: 0.27px;

    label {
        font-size: 16px;
    }
}

#react-select-3-input {
    width: 280px !important;
    z-index: 999999999 !important;
    border: none !important;
    padding: 3px !important;
    box-shadow: none !important;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-72gwz-ValueContainer {
    max-height: 50px;
}


.css-11g9up1-control {
    height: 60px !important;
}

.css-72gwz-ValueContainer {
    height: 50px !important;
}
.css-b8ldur-Input {
    input:focus {
        box-shadow: none !important;
    }
}

@import 'payment_mobile'