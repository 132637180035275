.two-cols {
    margin-bottom: 1.5rem;

    h3 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    h3.subheading {
        color : map-get($colors, 'green-thermomix') !important;
        top: -10px;
        position: relative;
    }

    img.img-fluid {
        background: #fff;
    }

    .content-cs {
        padding: 30px;

        p {
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 0.7rem;
        }

    }
}