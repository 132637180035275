nav {
    background: #fff;

    ul.navbar-nav {
        li {
            margin: 0px 12px;
            font-size: 15px;
            font-weight: bold;

            a {
                color : $nav-link !important;
            }
        }
    }

    img.logo {
        width: 160px;
    }

    img.icon-header {
        width: 24px;
    }
}

.shadow-down{
    -webkit-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.43);
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.43);
}
.shadow-up{
    -webkit-box-shadow: 0px -3px 4px -2px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 0px -3px 4px -2px rgba(0, 0, 0, 0.43);
    box-shadow: 0px -3px 4px -2px rgba(0, 0, 0, 0.43);
}