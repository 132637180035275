
.container__group__cuponcontainer__group__cupon
{
    height: 49px;
}
.container__group__cupon-icon {
    height: 49px;
    width: 49px;
    background: #159A3D 0% 0% no-repeat padding-box;
}

.container__group__cupon-pointer {
    cursor: pointer;
}

.container__cupon__title {
    font-style: normal;
    font-variant: normal;
    font-weight:  normal;
    font-size: 16px;
    font-family: Raleway;
    letter-spacing: 0.3px;
    color: #4B4A4B;
    margin-top: 18px;
    margin-bottom: 18px;
}
.container__group__cupon-input {
    padding : 5px !important;
    padding-left: 20px !important;
    border: 0.30000001192092896px solid  rgba(0, 0, 0, 0.1) !important;
    background-color: white;
    font-family: Raleway;

}

