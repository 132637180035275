.carrousel-container {
    .container-item {
        padding: 5px;

        h3 {
            font-weight: bold;
        }

        
        img {
            //max-height: 300px;
        }
        
    }
}
.slick-prev::before, .slick-next::before {
    position: absolute;
    font-size: 50px !important;
    line-height: 0.9 !important;
    opacity: 1 !important;
    color: #009a3d !important;
    
    display: block;
    z-index:1;
}
.slick-prev{
    left: -5px !important;
}
.slick-next{
    right: -5px !important;
}
.slick-prev::after, .slick-next::after {
    content:' ';
    display: block;
    width:40px !important;
    height:40px !important;
    border-radius: 50% !important;
    margin:auto;
    z-index:0;
    background-color:white;
}
.slick-arrow{
    position:absolute;
    width:60px !important;
    height:55px !important;
    min-width:60px !important;
    min-height:55px !important;
}
.carousel{
    &-home{
        & .banner-home > img{
            max-height: 85vh;
            object-fit: cover;
        }
        & h1, & .btn{
            font-size:1.5rem;
        }
        & p {
            text-align: center !important;
        }
    }

    &-data {
        position: absolute;
        top: 0;
        & .col {
            background-color: #ffffffe6;
            border-radius: 10px;
        }
    }

    // indicators
    &-indicators {
        li {
            width: 15px;
            height: 15px !important;
            border-radius: 100%;
            border-top: 0px;
            border-bottom: 0px;
            border: 1px solid map-get($theme-colors, 'secondary');

            &.active {
                background-color: map-get($theme-colors, 'primary');
            }
        }
    }

    //mobile
    @media (max-width: 768px) {
        &-home .banner-home > img{
            min-height: 45vh;
            object-fit: cover;
        }
    }
}