input {
    font-family: 'Raleway';
}
.form-chekout {
    font-family: Raleway !important;
    .form-checkout__user {
        padding-top: 26px;
        .form-checkout__user-label,
        .form-checkout__user-link {
            height: 19px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            font-family: Raleway;
            letter-spacing: 0.3px;
            color: #4b4a4b;
            opacity: 1;
            margin-bottom: 0;
            margin-right: 9px;
            margin-bottom: 23px;
            cursor: pointer;
        }

        .form-checkout__user-link {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .form-group {
        margin: 0;
        .mb-1 {
            margin: 0;
        }
        label {
            font-style: normal;
            font-variant: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            font-family: Raleway;
            letter-spacing: 0.34px;
            color: #4b4a4b;
            margin-bottom: 8px;
        }
        .form-control {
            height: 50px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 0.30000001192092896px solid #c4c4c4 !important;
            opacity: 1;
        }
    }

    .rs-radio-checker {
        height: 90px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border: 0.5px solid #c3c3c3;
        opacity: 1;
        width: 100%;
        margin-right: 19px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        input {
            border: 1px solid #159a3d;
        }
    }

    .rs-radio-inline {
        height: 90px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .lbl__radio__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .lbl__description__option {
        line-height: 45px;
        color: #159a3d;
        font: normal normal bold 20px/45px Raleway;
        height: 45px;
        margin: 0;
    }

    .is__payment_selection__radio {
        background: transparent !important;
    }
    .lbl__description__option_payment {
        margin-left: 15px !important;
    }
}

.rs-radio-checker:hover {
    background-color: #159a3d;
    cursor: pointer;
    .lbl__description__option {
        color: white;
    }
    input[type='radio'] + p:before {
        color: white;
    }
}

.label__wizard__title {
    font: normal normal 700 18px/19px Raleway;
    padding-bottom: 20px;
    border-bottom: 5px solid #159a3d;
    opacity: 1;
    letter-spacing: 0.34px;
    width: 241px;
    height: 35px;
}

.underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 35px;
}

.type-checkbox {
    margin-top: 30px !important;
}

.form-chekout .lbl__radio__container {
    align-items: flex-end !important;
}

.img_payment__retirement {
    width: 50px;
    height: 45px;
    margin-right: 45px;
}

.img_payment__dispatch {
    width: 62px;
    height: 39px;
    margin-right: 45px;
}
.is__type_selection__radio-tienda {
    margin-left: 19px;
}



.form-group,
.control__dispatch__home {
    margin: 0;
    .mb-1 {
        margin: 0;
    }
    label {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        font-family: Raleway;
        letter-spacing: 0.34px;
        color: #4b4a4b;
        margin-bottom: 8px;
    }
    .form-control {
        height: 50px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.30000001192092896px solid #c4c4c4 !important;
        opacity: 1;
    }
}

.control__dispatch__address,
.control__dispatch__aditional_information {
    margin-top: 18px !important;
}
.text__area__aditional_information {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #c4c4c4;
    height: auto !important;
}

textarea {
    background: #ffffff !important;
}

.btn:not(:disabled):not(.disabled) {
    background-color: #159a3d;
}
.list__address__suggestion{
    margin-left: 0;
    margin-top: -3px;
    border: 0.30000001192092896px solid #c4c4c4 !important;
    border-start-end-radius: 3px;

    li {
        padding: 5px;
        margin-left: -30px;
    }

    li:hover {
        cursor: pointer;
        background-color: #159a3d;
        color: #ffffff;
    }

}

.order__summary__check__out .label__wizard__title__summary__checkout {
    height: inherit;
}
                            

::marker {
    display: none !important;
    content: '';
    font-size: 0;
  }
  
@import 'delivery_mobile';