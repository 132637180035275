
.cover-page {
    background-color: #eaeaea;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 10vh;
    min-height: 430px;
    box-shadow: 0px -70px 50px 15px #00000096 inset;

    h1 {
        background: map-get($colors, 'green-thermomix');
        color : #fff;
        padding: 10px 30px;
        font-size: 1.7em;
        border-radius: 3px;
        font-weight: 700;
        z-index: 1;
    }

    @media (max-width: 768px) {
        background-size: 160% 100%;
        background-position: top;
        min-height: 122px;
        padding: 50px;
        background-color: #fff;

        h1 {
            top: 85px;
            position: relative;
        }
    }
}