
@import './components/new/wizard/wizards';

.item__product__checkout {
    height: 127px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #E8E8E8;

    .item__product__information {
        margin-top: 8px !important;
    }

}
.item__product__image-cart__checkout {
    width: 90px;
    height: 91px;
    margin: 14px 19px;
    margin-right: 5px;
}
.item__product__information-sku_checkout {
    margin : 0;
    font: normal normal 300 12px/4px Raleway;
    letter-spacing: 0.23px;
    color: #3A3C42;
    margin-bottom: 5px;
    margin-top: 20px;
}

.item__product__checkout .item__product__action {
    margin-right: 10px;
    padding-top: 19px;
}

@media only screen and (max-width: 1220px) and (min-width: 992px) {
 
    .item__product__image-cart__checkout {
        margin-left: 5px;
        margin-right: 0;
    }
    .item__product__image-cart__checkout {
        height: 50px;
        width:50px;
        margin-top:30px;
    }
    .item__product__checkout .item__product__information-title {
        font-size: 13px;
    }
    .item__product__information-price__checkout {
        display: flex;
        flex-direction: row;
        margin-left: -45px;
    }

    .item__product__information-price__checkout + .item__product__information-price
    {
        margin-top: 5px;
    }
    
}
