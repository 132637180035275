.container__header__buys {
    background-color: #fff;
    display:flex;
    justify-content:flex-start;
    flex-direction: row;
    height: 191px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 21px;
}
.container__header__buys-image  {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    height: 95px;
    img {
        margin-right: 45px;
    }
}
.container__header__buys-description {
    flex-grow: 1;
}
.container__header__buys-description-title {
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size:30px;
    line-height: 35px;
    font-family: Raleway;
    letter-spacing: 0px;
    color: #159A3D;
}

.container__header__buys-description-title_details
{
    letter-spacing: 0.53px;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    font-family: Raleway;
    width: 859px;
    span {
        font-weight: 700;
        color: #159A3D;
    }   

}
.container__body__buys {
    background: #fff;
}
.container__body__buys-title {
    background-color: #159A3D;
    height: 83px;
    padding-top: 26px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-around;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    font-family: Raleway;
    letter-spacing: 0.53px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 0;
}

.lbl__buys__info {
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    font-family: Raleway;
    letter-spacing: 0.34px;
    color: #3A3C42;
    margin-bottom: 0;
}

.lbl__buys__info_enfasis {

    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    font-family: Raleway;
    letter-spacing: 0.34px;
    color: #159A3D;
    margin-bottom: 0;
}

.container__body__buys-details {
    margin-left: 80px;
    margin-right: 80px;
}
.container__body__buys-information {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-top: 41px;
}
.lbl__buys{
    margin-bottom: 17px !important;
}
.lbl__buys__details {
    margin-bottom: 15px !important;
}
.container__body__buys-products {
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: Raleway;
    letter-spacing: 0.38px;
    color: #3A3C42;
}

.item__product__information__buy__paid p {
    margin: 0 ;
}
.item__product__information__buy__paid {

    .item__product__information-brand {
        margin-bottom: 7px !important;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        font-family: Raleway;
        letter-spacing: 1.8px;
        color: #3A3C42;
        text-transform: uppercase;
    
    }
    .item__product__information-title {
        letter-spacing: 0.47px;
        color: #3A3C42;
        height: inherit;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 25px;
        font-family: Raleway;
        margin-bottom: 9px;
    }
    .item__product__action-operation-number {
        font-style: normal;
        font-variant: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 13px;
        font-family: Raleway;
        letter-spacing: 0.38px;
        color: #3A3C42;
    }
}


.item__product__image__paid img {
    width: 128px !important;
    height: 128px !important;
}

.container__body__buys-summary_item {
    margin-bottom: 10px;
    .lbl-summary__title {
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 13px;
        font-family: Raleway;
        letter-spacing: 0.38px;
        color: #3A3C42;
        width: 70%
    }
    .lbl-summary__result_price{
        font-weight: 500;
        color: #159A3D !important;
    }
    .lbl-summary__result {
        font-style: normal;
        font-variant: normal;
        font-size: 20px !important;
        line-height: 13px;
        font-family: Raleway;
        letter-spacing: 0.38px;
        color: #3A3C42;
        font-weight: 500;
        font-size: 20px;
    }
    .lbl-summary__result_bold {
        font-weight: 700;
    }
}


.container__body__buys-summary {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    margin-top: 60px;

    .container__body__buys-summary_group {
        align-self: flex-end;
    }
    
    .container__body__buys-summary_item {
        align-self: flex-end;
        display: flex;
        width: 500px;
    }
}
.btn__back_to_index {
    width: 260px;
    margin-bottom: 66px;
}
.container__header__buys-image__responsive {
    display: none;
}
@media  (max-width: 1025px){
    .container__header__buys-description-title_details {
        font-size: 25px; 
        width: inherit;
    }
}

@media  (max-width: 991px){
    .container__paid {
        max-width: 100% !important;
    }
    .container__header__buys {
        height: inherit;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 30px #0000000A;
        border: 0.30000001192092896px solid #E8E8E8;
        border-radius: 2px;
        padding-bottom: 20px !important;
    }
    .container__header__buys-image__responsive {
        display: block;
        margin-bottom: 27px;
    }
    .container__header__buys-image {
        display: none;
    }

    .container__header__buys-description-title {
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size:23px;
        line-height: 27px;
        font-family: Raleway;
        letter-spacing: 0px;
        color: #159A3D;
        margin-bottom: 26px;
    }
    .container__header__buys-description {
       text-align: center;
    }
    .container__header__buys-description-title_details {
        font-style: normal;
        font-variant: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        font-family: Raleway;
        letter-spacing: 0.27px;
        color: #494949;
        text-align: justify;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .container__body__buys-title {
        height: 41px;
        background: #19A350 0% 0% no-repeat padding-box;
        border: 1px solid #F0F0F0;
        border-radius: 3px;
        font-size: 18px;
        padding-top: 0px;
        line-height: 41px;
    }

    .container__body__buys-information {
        display: block;
        margin-top: 29px;

    }
    .container__body__buys-details {
        margin-left: 0;
        margin-right: 0;
    }
    
    .lbl__buys__info  {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.3px;
    }

    .lbl__buys__info_enfasis {
        font-size: 16px;
    }
    .container__body__buys-products {
        font-size: 15px;
    }
 
    .item__product__image__paid img {
        width: 57px !important;
        height: 57px !important;
    }

    .item__product__information__buy__paid .item__product__information-brand {
        display: none;
    }

    .item__product__information__buy__paid .item__product__information-title {
        letter-spacing: 0.28px;
        color: #3A3C42;
        font-weight: 700;
        font-size: 15px;
    }
    .item__product__information__buy__paid .item__product__action-operation-number {
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-weight: 300;
        font-size: 15px;
        font-family: Raleway;
        letter-spacing: 0.28px;
        color: #3A3C42;
    }
    .container__body__buys-summary {
        margin-top: 5px;
    }
    .container__body__buys-summary .container__body__buys-summary_item {
        width: inherit;
    }
    .container__body__buys-summary_item .lbl-summary__title {
        font-weight: 300;
        font-size: 15px;
        line-height: 13px;
        font-family: Raleway;
        letter-spacing: 0.28px;
        color: #3A3C42;
        width: 65%;
    }
    .container__body__buys-summary .container__body__buys-summary_group {
        align-self: flex-start;
        width: 100%;
    }
    .container__body__buys-summary_item .lbl-summary__result {
        font-size: 15px !important;
        font-family: Raleway;
        letter-spacing: 0.28px;
        color: #3A3C42;
        font-weight: 700;
    }
    .container__body__buys-summary_responsive .container__body__buys-summary_group {
        height: 30px;
    }
    .container__body__buys-summary_responsive .container__body__buys-summary_group a {
        width: 100%;
    }
    .container__paid .breadcrumb__wizard {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.container--no__fluid {
    width: 95%;
}
.container__carro__compra {
    width: 95% !important;
}

.breadcrumb__wizard
{
    margin-left: 0;
}
.card-wizard {
    margin-left: 30px;
    
}
.row__carro__compra {
    padding-left: 30px;
}
/* .order__summary__check__out {
    margin-left: 30px;
} */

@media (max-width: 1500px){
    /* .input-group__color {
        width: 304px !important;
    } */
    .img_payment__dispatch {
        margin-right: 10px !important;
    }
    .img_payment__retirement {
        margin-right: 10px !important;
    }
    .container-desktop {
        width: 95%;
    }
    .container--no__fluid_wizard {
        margin-left: 15px;
    }
}

@media(max-width: 991px){
    .img_payment__dispatch {
        margin-right: 0 !important;
    }
    .img_payment__retirement {
        margin-right: 0 !important;
    }
}

@media (max-width: 1249px){
    .form-chekout .lbl__description__option_payment {
        font-size: 16px;
    }
}
@media (min-width: 991px) and (max-width: 1120px)  {
    .form-chekout .lbl__description__option_payment {
        font-size: 13px;
    }
}

@media  (max-width: 1300px)  {
    .order__summary__check__out {
        padding: 30px;
    }
}