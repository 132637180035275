// FOOTER MENU
footer {
    padding-top: 2rem;
    font-size: 0.9rem;
    color: map-get($colors, 'title-black');
    background-color: #fff;

    .subfooter {
        border-bottom: map-get($theme-colors, 'tertiary') 2px solid;
    }

    .copyright {
        margin: 20px 0;
    }

    .container-cs{
        padding-top: 10px;
        padding-bottom: 10px;
    }    

    @media (max-width: 768px) {
        .subfooter {
            width: 0;
            height: 0;
            visibility: hidden;
            font-size: 0;
        }
    }
}


.footer-menu {
    width: 0;
    height: 0;
    visibility: hidden;
    font-size: 0;

    img {
        width: 0px;
        height: 0px;
    }

    @media (max-width: 991px) {
        visibility: visible;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 75.26px;
        width: 100%;
        z-index: 1000;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;

        .menu-list {
            display: contents;

            .menu-item {
                float: left;
                list-style: none;
                width: 20%;
                padding: 10px;
                text-align: center !important;

                font-family: 'Merriweather';
                font-size: 9px;
                line-height: 19px;
                opacity: 1;
                font-weight: 900;
                font-style: normal;
                font-variant: normal; 
                letter-spacing: 0.17px;
                color: #4B4A4B;
                opacity: 1;

                .img-title-menu {
                    width: 100%;
                    height: 36px;
                    padding: 5px;
                }

                a {
                    color : map-get($theme-colors, 'secondary') !important;
                }
            }
            
        }
    }
}
// END FOOTER MENU