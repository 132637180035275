.pagination-cs {
    * {
        all: unset;
    }
    cursor: pointer;
    background: #fff;
    padding: 1rem 0;
    margin-bottom: 2.5rem;

    .pagination {
        .sr-only {
            visibility: hidden;
            width: 0px;
            font-size: 0px;
        }

        li {
            
        }

        a {
            padding: 10px;
            width: 10px;
            height: 10px;
            font-size: 1.5rem;
            background: transparent;
            text-decoration: none; 
            color : #444;

            &:hover {
                background: none;
            }

            &:active {
                background: none ;    
            }

            &.active{
                background: #d8d8d8 !important;
                color: #444;
                padding: 20px 10px;
                border: 0px;
                border-radius: 0px;
            }
        }

        .prev-cs, .next-cs {
            border: 1px solid  #444;
            border-radius: 100%;
        }

        .page-item {
            &.active {
                .page-link {
                    background: #d8d8d8 !important;
                    color: #444;
                    padding: 20px 10px;
                    border: 0px;
                    border-radius: 0px;
                }
            }
        }
    }
}
