// Modal de Beneficios
.modal-cs-1 {
    .modal-header {
        border: 0px;
        padding: 0.5rem 1rem;

        .close {
            padding: 1rem 1rem;
        }
    }

    .modal-body {
        padding: 0 1rem 1rem 1rem ;

        .container-btn-list {
            top: -230px;
            position: relative;

            .btn {
                border-radius: 100%;
            }

            .btn-next {
                float: right;
            }
        }
    }

    .icon {
        margin-top: 40px;
        max-width: 80px;
    }

    .title {
        margin-top: 10px;
        font-weight: bold;
        color : map-get($theme-colors, 'primary');
        position:relative;

        &:after {
            content:'';
            position:absolute;
            left:0; right:0;
            top:100%;
            margin:10px auto;
            width: 25%;
            height:3px;
            background:map-get($theme-colors, 'primary');
        }
    }

    .description {
        color : map-get($theme-colors, 'secondary');
        padding: 1.2rem;
        font-size: 1rem;
    }
}

// Modal de confirmacion de producto
.modal-cs-2 {
    .modal-header {
        border: 0px;
        padding: 0.5rem 1rem;

        .close {
            padding: 1rem 1rem;
        }
    }

    .modal-body {
        padding: 0 1rem 1rem 1rem ;
    }

    h2 {
        color : map-get($theme-colors, 'secondary');
    }

    .add-to-card {
        color : map-get($theme-colors, 'primary');
    }
}


// Modal de Contacto
.modal-cs-3 {
    h3 {
        font-weight: bold;
    }
    
    .btn-primary {
        margin-top: 20px;
    }
}
