// modals styles
.close__button__modal {
    margin-top: 24px;
    margin-right: 20px;
    margin-bottom: 73px;
}
.modal__body__user__register , .modal__body__user__login{
    padding-top: 0;
    padding-left: 88px;
    padding-right: 88px;
}

.title__modal__user__register , .title__modal__user__login{
    font: normal normal bold 25px/30px Raleway;
    letter-spacing: 0.34px;
    color: #4B4A4B;
    width: 401px;
    height: 60px;
}
.title__modal__user__login {
    height:30px;
}

.medium__line {
    border-bottom: 5px solid #159a3d;
    height:5px;
    width: 190px;
}
.title__modal__user__login {
    height:30px;
}

.container__modal__width .modal-dialog {
        @media(min-width: 768px) {
            /* by design others models has max-width */
            max-width: 577px !important;
        }
}

@media(min-width: 576px){
    .modal-dialog-centered {
        min-height: calc(100%) !important;
    }

}

@import 'userregister';
@import 'userlogin'


