.newsletter-cs {
    background-image: url('../../images/static/CONTENIDOS/newsleter.jpg');
    box-shadow: 0px -70px 50px 15px #00000096 inset;
    padding: 4.5rem 0rem;

    input {
        padding: 2.7rem;
        font-size: 1.5rem;
        margin: 1.2rem 0rem;
        border: 0px;
    }

    .btn-primary {
        margin-top: 2rem;
        font-size: 1.5rem;
        padding: 1rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}