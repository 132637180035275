
@media (max-width: 991px) {

    .form-chekout {
        font-family: Raleway !important;
        textarea {
            background: #F7F7F7 0% 0% no-repeat padding-box !important;
            height: 50px !important;
            padding-top: 3px !important;
            padding-left: 3px !important;
            font-weight: 300 !important;
            font-family: Raleway !important;
        }

        textarea:focus, textarea:active {
            font-weight: 300 !important;
        }

        .form-checkout__user {
            padding-top: 26px;
            .form-checkout__user-label,
            .form-checkout__user-link {
                height: 19px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                font-family: Raleway;
                letter-spacing: 0.3px;
                color: #4b4a4b;
                opacity: 1;
                margin-bottom: 0;
                margin-right: 9px;
                margin-bottom: 23px;
                cursor: pointer;
            }

            .form-checkout__user-link {
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .form-group {
            margin: 0;
            .mb-1 {
                margin: 0;
            }
            label {
                font-style: normal;
                font-variant: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                font-family: Raleway;
                letter-spacing: 0.27px;
                color: #4b4a4b;
                margin-bottom: 8px;
            }
            label.lbl_email__responsive{
                font-weight: 700;
                background: #F7F7F7 0% 0% no-repeat padding-box;
            }
            .form-control {
                height: 50px;
                border: 0.30000001192092896px solid #c4c4c4 !important;
                opacity: 1;
                background: #F7F7F7 0% 0% no-repeat padding-box;
            }
            .cart-delivery-type-box__mobile {
                margin-right: .2px;
            }
        }
    }   

   .cls__adress_mobile {
       margin-top: 15px !important;
   } 

   .lbl__radio__container__dispatch {
    margin-left: 0 !important;
    justify-content: flex-end !important;
    }
    .lbl__radio__container__store {
        margin-left: 0 !important;
        justify-content: flex-end !important;
    }

    .lbl__description__option_payment {
        width: 80%;
        height: 55px;
        margin-left: 10px !important;
    }

    .adress-top {
        margin-top: 32px !important;
    }

    .form-select-payment {
        margin-left: -5px !important;
    }
}

.form-select-payment {
    margin-left: 10px ;
}