.item__product__information-price__dashed {
    color: #666666;
    text-decoration: line-through;
    margin-right: 5px;
}
.item__product__information__sidebarcart {
    label {
        text-align: left !important;
        height: 19px;
    }
}
.cart__alert__delete {
    margin-top: 19px;
    background: #C4C4C491 0% 0% no-repeat padding-box;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    text-align: center;
    font-size: 14px; 
    font-family: Raleway;
    letter-spacing: 0px;
    color: #3A3C42;
    border-radius: none;
    border-color: none;
}

.cart__alert {
    font-family: Raleway;
    font-weight: 500;
    height: 55px;
    line-height: 55px;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    padding: 0;
}

.cart__alert__success {
    background-color: #006829;
}

.cart__alert__warning {
    background-color: #006829;
}


.btn__seguir__comprando {
    background-color: #006829 !important;
}
.sidebar-car-empty {
    margin-top: -1px;
}

.img__glass__mobile {
    height: 20px;
    width: 20px;
    margin-top: -5px;
}
input::placeholder {
    font-family: Raleway;
}