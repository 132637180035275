
input[type=radio] {
    margin-right: 10px;
}

.form-group {
    margin: 30px 0px;
    
    .invalid {
        color : red;
    }
}

.form-container {
    padding: 30px;
    margin-bottom: 40px;
    background: #fff;
    border-radius: 3px;

    
    input {
        background: map-get($theme-colors, 'tertiary');
        border-radius: 0px;
        //border: 0px;
        font-size: 15px;
        padding: 25px;
        font-family: 'Nunito', sans-serif;
        border: 1px solid map-get($theme-colors, 'tertiary') !important;
    }


    button {
        font-size: 20px;
        font-weight: bold;
    }
}

input {
    background: map-get($theme-colors, 'tertiary');
    border-radius: 0px solid;
    border: 0px !important;
    font-family: 'Nunito', sans-serif;
}

textarea {
    border: 0px !important;
    background: map-get($theme-colors, 'tertiary') !important;
    font-family: 'Nunito', sans-serif;
    padding: 25px !important;
}