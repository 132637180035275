
.form-user-login-modal input.form-control {
    background: #F7F7F7 0% 0% no-repeat padding-box !important;
    border: 0.30000001192092896px solid #DDDDDD !important;
    border-radius: 3px !important;
    opacity: 1 !important;
    margin-bottom: 25px !important;
}

.form-user-login-modal {
    padding: 0;
    margin-top: 5px;
}

.form-user-login-modal .form-group .invalid {
    color: red !important;
    margin: 0 !important;
    margin-top: -20px !important;
}

.container__remember__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.checkbox_lbl__remember__data {
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 17px;
    font-family:  Raleway;
    letter-spacing: 0.85px;
    color: #3A3C42;
    opacity: 1;
    display: flex;
}
.lbl__remember__data
{
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 17px;
    font-family:  Raleway;
    letter-spacing: 0.85px;
    color: #3A3C42;
    opacity: 1;
    display: flex;   
}

.lbl__remember__data:hover {
    cursor: pointer;
    text-decoration: underline;
}


@media  (max-width: 991px) {
    .modal__body__user__login {
        margin-top: -30px;
        padding-left: 15px;
        padding-right: 15px;
    
        .medium__line {
            width: 110px 
        }

        input.form-control {
            margin-bottom: 9.5px !important;
        }


        .form-group .invalid {
            margin-top: 0 !important;
        }
        .lbl__remember__data , .checkbox_lbl__remember__data {
            font-size: 14px;
        }

        .check__remember__data {
            height: 18px;
            width: 18px;
            top: 3px !important;
            left: 10px !important;
        }
        .btn__user__login {
            font-size: 14px;
            margin-top: 19px;
        }
        
    }

    .title__modal__user__login {
        font-size: 18px;
    }
    
}

