.rs-radio-checker__client {
    justify-content: flex-start !important;

    .lbl__radio__container {
        margin-left: 49px;
    }
}

.cart-delivery-type-box {
    cursor: pointer;
    text-align: center;
    height: 90px;
    user-select: none;
    padding: 0 !important;
    transition: all 0.5s;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 0.5px solid #c3c3c3;
    display: flex;
    flex-direction: column;
}

.cart-delivery-type {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: -5px;
}

.radio .check {
    position: absolute;
    top: 35px !important;
    left: 15% !important;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #159a3d;
}

.radio input:checked ~ .check {
    background-color: #159a3d;
    border: 1px solid white;
}

.lbl__description__option__type_document {
    font: normal normal bold 20px/19px Raleway;
    height: 90px;
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 90px;
}

.input-group-text__data__ticket {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #c4c4c4 !important;
    height: 50px;
}
.input__client_data_phone {
    border: 0.30000001192092896px solid #c4c4c4 !important;
    height: 50px;
}

.lbl__phone {
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    font-family: Raleway;
    letter-spacing: 0.34px;
    color: #4b4a4b;
    margin-bottom: 0 !important;
    padding-left: 0;
}
.client__data__name__surname {
    margin-top: 31px !important;
    margin-bottom: 16px !important;
}

.input-group-text__data__ticket {
    font-weight: bold;
}

.margin__data__client__ticket,
.margin__data__client__bill {
    margin-top: 46px;
}

.is__data__bill {
    margin-bottom: 16px !important;
}
.lbl__bill {
    font: normal normal bold 18px/19px Raleway !important;
    letter-spacing: 0.34px;
    color: #4b4a4b;
    margin-bottom: 11px !important;
}

.lbl__check__dispatch {
    margin-bottom: 11px !important;
}
.is__data__bill__who_collect {
    margin-left: 15px;
}
.is__data__bill__who_collect,
.is__data__bill__who_collect__surname {
    margin-top: 16px !important;
}
.client__data__razon__social , .client__data__giro {
    margin-top: 31px !important;
}

@import 'client_mobile'