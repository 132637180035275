.form-user-register-modal input.form-control{
   
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #DDDDDD;
    border-radius: 3px;
    opacity: 1;
    margin-bottom: 25px;
}

.frm-modal-user-register {
    padding: 0;
    margin-top: 30px;
}

.frm-modal-user-register .form-group .invalid {
    color: red !important;
    margin: 0 !important;
    margin-top: -20px !important;
}


@media  (max-width: 991px) {
    .modal__body__user__register {
        margin-top: -60px;
        padding-left: 15px;
        padding-right: 15px;
    
    }

    .title__modal__user__register {
        font-size: 18px;
        letter-spacing: 0.35px;
        width: 223px;
        height: inherit;
    }

    input.form-control {
        margin-bottom: 10px !important;
    }

    
    .frm-modal-user-register .form-group .invalid  {
        margin-top: 0 !important;
    }
    .btn__register__user {
        margin-top: 18px !important;
    }


}
