.card{
    &-products{
        & .title{
            min-height:6rem;
            max-height:6rem;
            overflow: auto;
            text-overflow: ellipsis;
            & p {
                line-height:1.5;
                font-size:13px;
                color:$color-black;
                font-family:$font-1;
                text-transform: uppercase;
            }
        }
        & .price{
            font-family:$font-1;

            @media (max-width: 768px) {
                font-size: .8rem;
                overflow-wrap: initial;
            }
        }   
    }

    &-recipes{
        & .overlay-shadow {
            /*box-shadow: inset 0 -70px 50px 15px rgba(0, 0, 0, 0.59);*/
            /*box-shadow: inset 0px -100px 50px -9px rgba(0, 0, 0, 0.85);*/
            box-shadow: inset 0px -200px 35px -75px rgba(0, 0, 0, 0.90)
        }
        & .img-icon{
            max-height:30px;
        }
        &  .card-img {
            height: 300px;
            object-fit:cover;
        }
    }

    &-benefits{
        
        & .card-body{
            border-top: 1rem solid map-get($colors, 'green-thermomix');
        }
        & .img-fluid{
            width: 5rem;
        }

        a {
            color: map-get($colors, 'title-black') !important;
        }
           
    }

    &-benefits-carousel {
        .slick-next, .slick-prev {
            width: 0px;
            height: 0px;
            visibility: hidden;
        }

        .slick-dots {
            bottom: -15px;
            z-index: 999;

            li {
                button:before {
                    content: ' ';
                    font-weight: bold;
                    font-size: 15px;
                    background: #444;
                    height: 7px;
                    border-radius: 15px;
                }
            }
        }
    }
    
    &-categories{
        a {
            color: map-get($colors, 'title-black') !important;
        }

        & .img-fluid{
            height:6rem;
        }

        .right_arrow {
            height: 30px;
            width: 30px;
            padding : 2px;

            .img-fluid{
                height: 10px;
            }
        }

        & h3{
            font-size:1.3rem;
            overflow-wrap: normal;
        }
    }
    &-help{
        & .card::before{
            content: ' ';
            width: 100%;
            height: 100%;
            background: #00000070;
            z-index: 0;
            position: absolute;
        }
    }

    &-handbook {
        a {
            color: map-get($colors, 'title-black') !important;
        }
    }

    &-cookidoo {
        & .card::before{
            content: ' ';
            width: 100%;
            height: 100%;
            background: #00000073;
            z-index: 0;
            position: absolute;
        }
    }

    &-jointo {
        & .img-fluid {
            max-height: 24px;
        }

        @media (max-width: 768px) {
            .title-text {
                font-size: 9px;
            }
            
            & .img-fluid {
                max-width: 20px;
                max-height: 20px;
                top: -3px;
                left: 10px;
                position: absolute;
            }
        }
    }
}